<template>
	<div style="text-align: left;">
		<div v-if="this.hierarchyDiagramPath.length != 34" style="">
			<img style="width: 50rem;margin-top: 1rem;" :src="hierarchyDiagramPath"/>
		</div>
		<Table :columns="funcCol" :data="funcData" style="width: 100%; "></Table>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				funcCol: [
					{
						title: 'Function Name',
						key: 'name',
						width: 250
					},
					{
						title: 'Description',
						key: 'description'
					}
					// {
					// 	title: 'Completion date',
					// 	key: 'date',
					// 	width: 150
					// },
					// {
					// 	title: 'Lines of code ',
					// 	key: 'lines',
					// 	width: 140
					// }
				],
				funcData: [],
				hierarchyDiagramPath: '',
			}
		},
		methods:{
			fakeInit(){
				this.funcData = [
					{
						name: 'RL-Adventure',
						description: `This is easy-to-follow step-by-step Deep Q Learning tutorial with clean readable code.`,
						date: '2016-10-03',
						lines: '38431'
					},
					{
						name: 'Neural Episodic Control',
						description: `First, I recommend to use small test problems to run experiments quickly. Then, you can continue on environments with large observation space.`,
						date: '2018-10-03',
						lines: '20572'
					},
					{
						name: 'Rainbow',
						description: `Combining Improvements in Deep Reinforcement Learning`,
						date: '2020-10-03',
						lines: '16390'
					},
					{
						name: 'Noisy Networks for Exploration',
						description: `Carefully go through the paper. Try to see what is the problem the authors are solving. `,
						date: '2019-10-03',
						lines: '7989'
					}
				];
			},
			init(){
				var Tself = this;
				var val = this.$route.query.id;
				this.$axios({
					headers: {
						'Content-Type': 'application/json'
					},
					method: 'post',
					url: '/project/findById',
					data: val
				})
				.then(function (response) {
					var data = response.data.data.items;
					Tself.hierarchyDiagramPath = data.hierarchyDiagramPath;
					for(var i = 0; i< data.functionList.length; i++){
					    var func = data.functionList[i].split("---")
						Tself.funcData.push({
							name: func[0],
							description: func[1]
						})
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			}
		},
		mounted() {
			this.init();
		}
	}
</script>

<style>
	#TreeChart{
		min-height: 25rem;
	}
</style>
